.btn-modal {
  background: none !important;
  text-decoration: underline;
  color: #404040;
  padding: 0 !important;
}

.btn-modal:hover {
  color: #f06c00;
}
