.r-assetCard-alarm {
  border-left: 5px solid #d50000;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-radius: 2%;
}

.r-card-suspension {
  border-left: 5px solid black;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-radius: 2%;
}

.r-assetCard-noTag {
  border: 1px solid #d8d8d8;
}

.r-assetCard-red {
  border-left: 5px solid #d50000;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-radius: 2%;
}

.r-assetCard-grey {
  border-left: 5px solid #404040;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-radius: 2%;
}

.ln-u-color-white {
  color: #fff;
}
