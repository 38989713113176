.img-guide-btn-modal {
  background: none !important;
  text-decoration: underline;
  color: #404040;
  padding: 0 !important;
  font-weight: bold;
}

.img-guide-btn-modal:hover {
  color: #f06c00;
}

.modal-images {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
