.no-background {
  background: inherit !important;
}

nav#r-filterList {
  margin-bottom: 0 !important;
}

button.ln-c-tabs__link.r-filter {
  background: inherit !important;
}

button.ln-c-tabs__link.r-filter:hover {
  background-color: var(--ln-color-transparency-tabs) !important;
}

div.ln-c-card.r-asset-card {
  border-radius: 2%;
}

div#r-pagination-card {
  background: inherit !important;
}
