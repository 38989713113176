.r-alarm-history-container {
  display: flex;
}

.r-alarm-overview-container {
  display: flex;
}

.dm__filter-panel-wrapper {
  background-color: white;
}

.r-filter-panel {
  position: sticky;
  top: 100px;
  height: 100vh;
  overflow-y: auto;
}

@media screen and (max-width: 720px) {
  .r-filter-panel {
    display: none;
  }

  .r-filter-header-desktop {
    display: none;
  }
}

@media screen and (min-width: 720px) {
  #r-filters-apply-button {
    display: none;
  }
}
