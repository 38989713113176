.a-page-push {
  @extend .ln-u-push-ends;
}

.a-no-link-style,
.a-no-link-style:hover,
.a-no-link-style:active,
.a-no-link-style:visited {
  color: inherit;
  text-decoration: inherit;
}

.ln-o-container--xs {
  max-width: 35rem;
}

@media only screen and (max-width: 600px) {
  .ln-o-container--xs.r-pageName {
    margin-top: 3rem !important;
  }

  button#a-back-button {
    margin-top: 2.5rem;
  }
}

// @media screen and (min-width: 720px) {
.ln-o-page__header + .ln-o-page__body {
  margin-top: 0px;
}
// }
