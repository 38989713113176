$ln-webpack: true;
$ln-theme: 'sainsburys';
$a-mobile-width: 700px;
$ln-border-size-large: 2px;
$a-breakpoints: (
  mobile: 556px
);

@import '@jsluna/style/scss/main.scss';

//Custom
@import './components/common';
@import './components/utils';
@import './components/refrigerationDetails';
@import './components/refrigerationList';
@import './components/tag';
@import './components//imageUpload';
@import './components/link';
@import './components/imageGuidance';
@import './components/passiveFeedback';
@import './components/filterPills';
@import './components/filterPanel';
@import './components/colleagueStoreSelector';
@import '@jsainsburyplc/retail-barcode-scanner/scss/main.scss';

/* App Specific Styles Go Here */

[id^='navLink-'].active {
  @extend .is-active;
}

#site-title {
  text-decoration: none;
}
